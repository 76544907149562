const danish = () => {
    return {
        image_errors: {
            6000: "Kunne ikke analysere ansigtsbillede",
            6001: "Billedet indeholder ingen ansigter",
            6002: "Billedet indeholder mere end ét ansigt",
            6003: "Ansigtet er tilsløret",
            6004: "Øjnene er lukkede",
            6005: "Bærer solbriller",
            6006: "Ansigtet er vippet",
            6007: "Ansigtet er for lille"
        },
        image_error_labels: {
            6000: "Kunne ikke analysere ansigtsbillede",
            6001: "Intet ansigt",
            6002: "Kun ét barn",
            6003: "Ansigtet er tilsløret",
            6004: "Øjnene er lukkede",
            6005: "Bærer solbriller",
            6006: "Lige-på syn",
            6007: "Ansigtet er for lille",
            normal: "Klart og godt belyst"
        },
        arias: {
            stepper: {
                start: "Du er på trin",
                end: "af"
            },
            back_button: "Tilbage",
            cover_selection: "Omslag nummer",
            image_to_load: "Upload billede",
            image_load: "Uploader billede",
            loaded_image: "Billede uploadet",
            exampleDialogTitle: "Billedeksempel",
            next: "Næste",
            prev: "Forrige",
            fill_requironments: "",
            name_validation: "Navnevalidering"
        },
        please_upload_image: "Venligst upload billedet",
        general: {
            next: "Næste",
            pageTitle: "Bogoprettelse",
            try_again: "Prøv igen"
        },
        error: {
            error_upload: "Der var et problem med at opdatere billedet",
            many_faces: "Venligst upload et billede med kun ét ansigt"
        },
        stage1: {
            image_is_here: "Upload et billede her",
            image_desc_1: "Upload et klart billede af dit barn for at gøre",
            image_desc_2: " dem til stjernen i deres egen historie!",
            several_things: "Tips til det perfekte heltebillede:",
            browse_files: "Gennemse filer",
            several_things_cont: {
                first: "Upload et billede af høj kvalitet af barnet, hvor de er i centrum",
                second: "2. Eksempler på et korrekt billede"
            },
            title: "Start dit barns eventyr!",
            explain: "Upload først billedet, du kan trække eller klikke på knappen",
            file_size: "Maks størrelse:",
            note: "Et billede i bedre kvalitet og med den rette vinkel vil give et bedre resultat",
            example: "Vis mig et eksempel",
            popup_bullets: {
                bullet_one: "Billedet skal have ét ansigt, uden briller, parykker, kostumer osv.",
                bullet_two: "Billedet skal tages med ansigtet foran kameraet i en vinkel, hvor hele ansigtet kan ses",
                bullet_three: "Billedet skal være med åbne øjne"
            },
            popup_exit: "Jeg forstår",
            cropper_text: "Centrer dit barns ansigt i rammen for de bedste resultater"
        },
        "upload_photo_stage": {
            "title": "Upload dit barns billede",
            "image_desc": "Træk et billede eller klik på knappen",
            "upload_photo": "Upload billede",
            "replace_photo": "Erstat billede",
            "upload": "Upload",
            "secure_message": {
                "part_one": "Det er vigtigt at vide, at minbørnebog-tjenesten er",
                "part_two": "privat, sikker og sikrer, at der ikke bruges data af tredjeparter.",
                "part_three": "Dine billeder og oplysninger forbliver sikre og beskyttede."
            },
            "title_tips_photo": "Tips til det perfekte hero-shot:",
            "subtitle_tips": "Upload et billede i høj kvalitet af barnet, hvor de er i centrum",
            "cropper_text": "Tips til det perfekte hero-shot: afstanden skal være en smule mindre"
        },
        stage2: {
            nice_photo: "Åh! Dette er et flot billede!",
            continue_text: "Lad os personliggøre dit barns eventyr!",
            name_validation_1: "Er",
            name_validation_2_male: "barnets fornavn?",
            name_validation_2_female: "barnets fornavn?",
            yes: "Ja",
            no: "Nej",
            fields: {
                name_male: "Barnets navn",
                name_female: "Barnets navn",
                age_hero: "Alder",
                gender: {
                    male: "Dreng",
                    female: "Pige"
                },
                age: "Alder:",
                apply: "Gem",
                email: "Email",
                phone: "Telefonnummer",
                email_validation: "Forkert email-format",
                sex: "Køn",
                age_validation: "Alder skal være"
            },
            terms: "Jeg accepterer story magic ",
            terms_part_2: "(vilkår)",
            terms_link: "https://mebook.ai/en/refund_returns/",
            terms_sms: "Ved at klikke på 'Gem' accepterer du vores vilkår"
        },
        stage3: {
            kids_name_male: "Vores helts navn: ",
            kids_name_female: "Vores heltindes navn er: ",
            kids_age_female: "Og hun er: ",
            kids_age: "Og han er: ",
            hairColor: "Hårfarve",
            eyeColor: "Øjenfarve",
            skinColor: "Hudfarve",
            hairStyle: "Frisure"
        },
        stage4: {
            title: "Vælg din historie",
            buttonText: "Lad magien begynde"
        },
        loading_texts: {
            text1: "Gnistrende fantasi...",
            subtext1: "Kun få magiske øjeblikke væk!",
            text2: "Frigiv kreativitet...",
            subtext2: "Dit personlige eventyr brygges!",
            text3: "Omfavn forventningen...",
            subtext3: "Din AI-bog er undervejs!",
            text4: "Fordyb dig i forundring...",
            subtext4: "Dit personlige eventyr brygges!",
            text5: "Fængslende øjeblikke foran...",
            subtext5: "Vi skaber en historie specielt til dig!"
        },
        click_me: "Klik på mig",
        end_process: {
            main_text: "Fantastisk! Vælg en af de muligheder, du vil \n" + "bruge til dit bogomslag",
            buttonText: "Gå til kassen",
            choose_cover_pdf: "Digital e-bog",
            choose_cover_hard: "Hardcover-bog",
            p_description_pdf:
                "En høj-kvalitets digital e-bog i PDF-format, smukt designet og læsbar fra hvor som helst og når som helst.",
            p_description_hard: "Trykt bog med hardcover og tryk i høj kvalitet, med et moderne design",
            price_pdf: "$15",
            price_book: "$39"
        },
        stage5: {
            banned_title:
                "Vi er dedikerede til at sikre, at denne enestående service er tilgængelig for alle vores kunder. Som led i denne indsats har vi indført en begrænsning på bogoprettelse til tre gange dagligt.",
            desc: "Du har dog stadig mulighed for at vælge fra tidligere genererede bøger for din bekvemmelighed."
        },
        do_not_refresh_the_page: "Venligst opdater ikke siden",
        example_of_first_page: "* Eksempel på første side",
        continue_editing_book: "For at fortsætte med at redigere bogen, vælg en af følgende muligheder",
        choose: "Vælg",
        pdf_image: "https://mebook.ai/wp-content/uploads/2023/09/U5XQa1yO5-1-e1694077013211-1024x1021.png",
        hard_image: "https://mebook.ai/wp-content/uploads/2023/09/%D7%98%D7%91%D7%90%D7%9C%D7%98-1.png",
        past_books: "Tidligere bøger",
        terms_modal_h: "# Betingelser for Upload af Billeder - minbørnebog.dk",
        terms_modal: [
            {
                title: "## Indledning",
                p: 'Dette dokument specificerer vilkår og betingelser for upload af billeder til minbørnebog.dk (herefter "Virksomheden"). Venligst læs følgende betingelser grundigt før upload af billeder. Upload af billeder udgør fuld accept af disse betingelser.'
            },
            {
                title: "## Samtykkeerklæring",
                p:
                    "Ved upload af billeder til websitet erklærer og bekræfter du at:\n" +
                    "\n" +
                    "1. Du er barnets forælder eller juridiske værge\n" +
                    "2. Du er mindst 18 år gammel\n" +
                    "3. Du har den juridiske ret til at bruge billederne og tildele Virksomheden de tilladelser, der er specificeret i dette dokument\n" +
                    "4. Du har indhentet udtrykkelig tilladelse fra alle andre juridiske værger af barnet (hvis relevant)\n" +
                    "5. Du forstår, at brug af tjenesten sker på eget ansvar\n"
            },
            {
                title: "## Brug af Billeder",
                p:
                    "Upload af billeder giver Virksomheden:\n" +
                    "\n" +
                    "1. En ikke-eksklusiv licens til at bruge billederne udelukkende til:\n" +
                    "   - Fremstilling af den personlige bog, du har bestilt\n" +
                    "   - Midlertidig opbevaring af billeder i 30 dage\n" +
                    "   - Nødvendig teknisk behandling af billeder\n" +
                    "\n" +
                    "2. Virksomheden forpligter sig til:\n" +
                    "   - Ikke at bruge billeder til markedsføringsformål\n" +
                    "   - Ikke at overdrage billeder til tredjepart\n" +
                    "   - At slette billeder automatisk efter 30 dage\n" +
                    "   - At opbevare billeder sikkert i henhold til GDPR\n"
            },
            {
                title: "## Begrænsninger for Billedindhold",
                p:
                    "Det er ikke tilladt at uploade billeder:\n" +
                    "\n" +
                    "1. Af børn, som ikke er under din juridiske varetægt\n" +
                    "2. Der indeholder upassende eller krænkende indhold\n" +
                    "3. Der krænker ophavsrettigheder\n" +
                    "4. Der er taget uden samtykke\n" +
                    "5. Der indeholder følsomme personoplysninger\n"
            },
            {
                title: "## Sikkerhed og Databeskyttelse",
                p:
                    "Virksomheden forpligter sig til:\n" +
                    "\n" +
                    "1. At sikre billeder med avancerede sikkerhedsforanstaltninger\n" +
                    "2. At overholde GDPR og dansk databeskyttelseslovgivning\n" +
                    "3. At informere brugere i tilfælde af sikkerhedshændelser\n" +
                    "4. At begrænse adgang til billeder til autoriseret personale\n"
            },
            {
                title: "## Ansvarsbegrænsning",
                p:
                    "Brugeren accepterer at:\n" +
                    "\n" +
                    "1. Brug af tjenesten sker på eget ansvar\n" +
                    "2. Virksomheden er ikke ansvarlig for indirekte eller følgeskader\n" +
                    "3. Virksomhedens samlede ansvar er begrænset til transaktionsbeløbet\n" +
                    "4. Virksomheden er ikke ansvarlig for tab eller skade på billeder af årsager uden for dens kontrol\n"
            },
            {
                title: "## Retskrav og Juridiske Procedurer",
                p:
                    "1. Brugeren forpligter sig til at holde Virksomheden skadesløs mod ethvert krav der opstår som følge af brud på disse betingelser\n" +
                    "2. Enhver tvist skal behandles ved danske domstole\n" +
                    "3. Dansk ret finder anvendelse på alle aspekter af tjenestens brug\n"
            },
            {
                title: "## Ophavsret og Immaterielle Rettigheder",
                p:
                    "1. Brugeren bevarer ophavsretten til de originale billeder\n" +
                    "2. Virksomheden bevarer ophavsretten til bogens design og format\n" +
                    "3. Det endelige produkt må ikke bruges til kommercielle formål\n"
            },
            {
                title: "## Ændringer i Brugervilkår",
                p:
                    "1. Virksomheden forbeholder sig ret til at opdatere betingelserne\n" +
                    "2. Væsentlige ændringer kræver fornyet samtykke\n" +
                    "3. Fortsat brug efter ændringer udgør accept af opdaterede betingelser\n"
            },
            {
                title: "## Annullering og Ophør",
                p:
                    "1. Billedupload kan annulleres før produktionen påbegyndes\n" +
                    "2. Efter produktionsstart kan brug af billeder ikke annulleres\n" +
                    "3. Virksomheden kan fjerne billeder, der overtræder betingelserne\n"
            },
            {
                title: "## Kontakt",
                p:
                    "For spørgsmål eller henvendelser:\n" +
                    "- E-mail: support@minboernebog.dk\n" +
                    "- Svartid: Inden for 24 timer på hverdage\n"
            },
            {
                title: "Sidst opdateret: 31.10.2024",
                p: ""
            }
        ],
        preebook_title: "Dine bøger",
        preebook_subTitle: "Bøger nedenfor er ikke i indkøbskurven",
        otp: {
            title: "Log venligst ind med dit telefonnummer",
            title_email: "Indtast din e-mail",
            title_2: "SMS med koden er på vej",
            title_2_email: "E-mail med koden er på vej",
            code_is_invalid: "Koden er ugyldig",
            change_phone_number: "Skift telefonnummer",
            send_again: "Send igen",
            enter_your_number: "Del dit telefonnummer, og lad os begynde den magiske rejse!",
            enter_your_email: "Del din email, og lad os begynde den magiske rejse!",
            change_email: "Skift e-mail"
        }
    };
};

export default danish;
