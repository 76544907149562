const CONSTS = {
    error_images_links: {
        normal: "https://print.mebook.ai/static/frame/errors/normal.png?width=150",
        6004: "https://print.mebook.ai/static/frame/errors/close-eyes.png?width=150",
        6007: "https://print.mebook.ai/static/frame/errors/far.png?width=150",
        6003: "https://print.mebook.ai/static/frame/errors/hasum.png?width=150",
        6002: "https://print.mebook.ai/static/frame/errors/many-faces.png?width=150",
        6001: "https://print.mebook.ai/static/frame/errors/back.png?width=150",
        6006: "https://print.mebook.ai/static/frame/errors/left.png?width=150"
    },
    valid_errors: [6004, 6007, 6003, 6002, 6001, 6006],
    preview_images: [
        "https://print.mebook.ai/static/frame/examples/example2.png?width=400",
        "https://print.mebook.ai/static/frame/examples/example3.png?width=400",
        "https://print.mebook.ai/static/frame/examples/example-4.jpg?width=400",
        "https://print.mebook.ai/static/frame/examples/example4.jpeg?width=400",
        "https://print.mebook.ai/static/frame/examples/example5.jpeg?width=400"
    ]
};

// export const API_URL = 'https://api.mebook.ai/api';
export const API_URL = 'https://qa-api.mebook.ai/api';

export const EDITOR_URL = 'https://dev-editor.mebook.ai/';
// export const EDITOR_URL = 'https://editor.mebook.ai/';

export default CONSTS;
