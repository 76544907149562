import React, { useState, useRef } from "react";
import CONSTS from "../../../consts";
import styled from "styled-components";
import { ReactComponent as DefaultChildPhoto } from "../../images/default-user-icon.svg";
import { ReactComponent as SideIcon1 } from "../../images/side-icon-1.svg";
import { ReactComponent as SideIcon2 } from "../../images/side-icon-2.svg";
import { ReactComponent as SideIcon3 } from "../../images/side-icon-3.svg";
import { ReactComponent as SideIcon5 } from "../../images/side-icon-5.svg";
import { ReactComponent as SideIcon6 } from "../../images/side-icon-6.svg";
import { ReactComponent as SideIcon7 } from "../../images/side-icon-7.svg";
import { getMobileQuery } from "../../../common/utils";

export const LeftPanel = styled.div`
    background-color: #f9a530;
    width: 20%;
    height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;

    .child-photo {
        min-width: 100px;
        min-height: 100px;
        width: 40vh;
        height: 40vh;
        background-color: white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: calc(100% - 20vh);
        overflow: hidden;
        cursor: pointer;

        svg {
            width: 100%;
            height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @media (max-width: 1200px) {
            width: 30vh;
            height: 30vh;
            left: calc(100% - 15vh);
        }

        @media (max-width: 768px) {
            min-width: 136px;
            min-height: 136px;
            width: 22vh;
            height: 22vh;
            left: 50%;
            transform: translateX(-50%);
            top: calc(100% - 16vh);
        }

        @media (max-width: 400px) {
            min-width: 14vh;
            min-height: 14vh;
            width: 188px;
            height: 188px;
        }
    }

    .responsive-icons {
        position: relative;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        svg {
            position: absolute;
            width: 40px;
        }

        .icon1 {
            width: 50px;
            top: 5%;
            left: 67%;
        }

        .icon2 {
            top: 15%;
            left: 20%;
        }

        .icon3 {
            top: 40%;
            left: 10%;
        }

        .icon4 {
            bottom: 30%;
            left: 35%;
        }

        .icon5 {
            bottom: 15%;
            right: 25%;
        }

        .icon6 {
            width: 50px;
            bottom: 5%;
            left: 15%;
        }

        @media (max-width: 768px) {
            svg {
                width: 22px;
            }

            .icon1 {
                width: 25px;
                top: 8%;
                left: 65%;
            }

            .icon2 {
                top: 25%;
                left: 85%;
            }

            .icon3 {
                width: 18px;
                top: 30%;
                left: 25%;
            }

            .icon4 {
                top: 15%;
                left: 0;
            }

            .icon5 {
                bottom: 15%;
                right: 5%;
            }

            .icon6 {
                width: 25px;
                bottom: 5%;
                left: 5%;
            }
        }
    }

    @media (max-width: 768px) {
        width: 100%;
        height: 25vh;
        position: relative;
    }
`;

function LeftSidePanel({ lang, langData, handleUploadClick, imageBlob }) {
    const imageInputRef = useRef(null);
    const fileInputRef = useRef(null);
    const dnaRef = useRef(null);

    const [openSelection, setOpened] = useState(false);
    const [, s_onImageFocus] = useState(0);

    const [, s_blobBuf] = useState(null);

    const innerWidth = getMobileQuery();
    const isMobile = innerWidth < 768;
    const images = CONSTS.preview_images;

    return (
        <LeftPanel>
            <div className="responsive-icons">
                <SideIcon1 className="icon1" />
                <SideIcon2 className="icon2" />
                {isMobile ? <SideIcon6 className="icon3" /> : <SideIcon3 className="icon3" />}
                <SideIcon2 className="icon4" />
                {isMobile ? <SideIcon7 className="icon5" /> : <SideIcon5 className="icon5" />}
                <SideIcon1 className="icon6" />
            </div>
            <div className="child-photo">
                {imageBlob ? (
                    <img
                        aria-label={langData.arias.loaded_image}
                        src={imageBlob}
                        alt={langData.arias.loaded_image}
                        onClick={(e) => handleUploadClick(e)}
                    />
                ) : (
                    <DefaultChildPhoto onClick={(e) => handleUploadClick(e)} />
                )}
            </div>
        </LeftPanel>
    );
}

export default LeftSidePanel;
