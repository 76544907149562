import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "../style.css";

const InfiniteProgressBar = ({ lang }) => {
    const [progress, setProgress] = useState(1);

    useEffect(() => {
        const interval = setInterval(() => {
            if (progress < 100) {
                setProgress(progress + 1);
            } else {
                setProgress(0);
            }
        }, 1200);
        return () => clearInterval(interval);
    }, [progress]);

    return (
        <div className="loading-bar">
            <div className="infinite-progress-bar">
                <motion.div
                    className="bar"
                    initial={{ width: "0" }}
                    animate={{ width: `${progress}%` }}
                    transition={{ duration: 0.5 }}
                />
            </div>
        </div>
    );
};

export default InfiniteProgressBar;
