import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { getFontSize, makeImgAsPrint } from "../../common/utils";

const WrapperStyled = styled.div`
    width: 100%;
    height: 100%;
`;

const TextStyled = styled.div`
    position: absolute;
    bottom: 10%;
    text-align: center;
    width: 100%;
    text-shadow: 1px 1px 2px #000;
`;

const ContStyled = styled.div`
    position: relative;

    img {
        border-radius: 3px;
        display: block;
        object-fit: cover;
    }

    box-shadow:
        0 0 5px -1px black,
        inset -1px 1px 2px rgba(255, 255, 255, 0.5);
    margin: auto;
    border-radius: 5px;
    background-size: cover;
    width: 250px;
    height: 250px;

    &.selected {
    }

    @media (max-width: 767px) {
        width: 100%;
        height: inherit;
    }
`;

const BookCoverPrev = ({
    imageUrl,
    lang,
    text,
    coverDesign,
    index,
    isSelected,
    partOfBook,
    parentWidth,
    isForCapture,
    bookId
}) => {
    const wrapperRef = useRef(null);
    const [height, s_height] = useState("auto");
    const [backgroundImage, setBackgroundImage] = useState(imageUrl);

    const offsetWidth = wrapperRef?.current?.offsetWidth || 0;

    useEffect(() => {
        if (wrapperRef?.current) {
            s_height(offsetWidth);
        }
    }, [offsetWidth, imageUrl, parentWidth, wrapperRef?.current]);

    const fontName = coverDesign?.fontName || "Suez One";
    const fontWeight = coverDesign?.fontWeight || "400";
    const fontStyle = coverDesign?.fontStyle || "normal";
    const strokeColor = coverDesign?.strokeColor;
    const strokeWidth = coverDesign?.strokeWidth;
    const textColor = coverDesign?.textColor || "fff";
    const textSize = coverDesign?.textSize;

    useEffect(() => {
        if (isForCapture) {
            const printImage = makeImgAsPrint(imageUrl);
            const imageUrlToBase64 = (url, callback) => {
                const img = new Image();
                img.crossOrigin = "Anonymous";
                img.onload = () => {
                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d");
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0);
                    const dataURL = canvas.toDataURL("image/png");
                    callback(dataURL);
                };
                img.src = url;
            };

            imageUrlToBase64(printImage, (base64) => {
                setBackgroundImage(base64);
            });
        }
    }, [imageUrl, isForCapture]);

    let background = `linear-gradient(to right, rgb(60, 13, 20) 2px, rgba(255, 255, 255, 0.5) 5px, rgba(255, 255, 255, 0.25) 7px, rgba(255, 255, 255, 0.25) 8px, transparent 12px, transparent 12px, rgba(255, 255, 255, 0.25) 16px, transparent 19px), url(${backgroundImage})`;
    if (lang === "he") {
        background = `linear-gradient(to left, rgb(60, 13, 20) 2px, rgba(255, 255, 255, 0.5) 5px, rgba(255, 255, 255, 0.25) 7px, rgba(255, 255, 255, 0.25) 8px, transparent 12px, transparent 12px, rgba(255, 255, 255, 0.25) 16px, transparent 19px), url(${backgroundImage})`;
    }

    let fontSize = getFontSize(textSize, partOfBook);

    const bookCoverId = `book-cover-${index}`;

    let classes = `book-prev-component lang-${lang}`;
    if (isSelected) {
        classes += " selected-book-page";
    }
    if (isForCapture) {
        classes += " capture-po";
    }
    if (bookId) {
        classes += ` bookId-${bookId}`;
    }

    let divHeight = height;
    if (parentWidth) {
        divHeight = parentWidth / 2;
    }

    if (divHeight !== 300) {
        const percent = (divHeight / 300) * 100;
        const increaseFor = percent - 100;
        const plusNumber = (fontSize / 100) * increaseFor;
        fontSize = fontSize + plusNumber;
    }

    const style = {
        backgroundImage: background,
        backgroundSize: "cover",
        height: divHeight
    };

    if (isForCapture) {
        style.height = 700;
        style.width = 700;
    }

    return (
        <WrapperStyled ref={wrapperRef} id={bookCoverId} className={classes}>
            <ContStyled className={isSelected ? "selected kkop" : "kkop"} style={style}>
                <TextStyled
                    style={{
                        fontFamily: fontName,
                        fontWeight,
                        fontStyle,
                        fontSize: `${fontSize}px`,
                        color: textColor
                    }}
                >
                    {text}
                </TextStyled>
            </ContStyled>
        </WrapperStyled>
    );
};

export default BookCoverPrev;
