import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { transformImage } from "../../../common/utils";

const MediaOageStyled = styled.div`
    z-index: 100;
    position: relative;

    img {
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
    }

    &.lang-img-dpwz-he {
        img {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 4px;
            border-top-right-radius: 4px;
        }
    }
`;

const MediaPreviewStyled = styled.div`
    z-index: 102;

    img {
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        border-bottom: 6px solid transparent;
        transition: border 0.3s ease 0s;
        z-index: 103;
        position: relative;

        @media (max-width: 500px) {
            border-bottom: 2px solid transparent;
        }
    }

    &.selected {
        img {
            border-bottom: 6px solid rgb(255, 196, 61);

            @media (max-width: 500px) {
                border-bottom: 2px solid rgb(255, 196, 61);
            }
        }
    }
`;

const PreviewWrapperStyled = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    position: absolute;
    bottom: 7px;
    grid-gap: 5px;
    padding: 0 10px;
    z-index: 101;
`;

const MediaBookPage = ({ imgs, lang, isShown, langData }) => {
    const pikaRefs = [];

    const [imageToPreviewSelectedIndex, s_imageToPreviewSelectedIndex] = useState(0);
    const transformedSelectedImage = transformImage(imgs[imageToPreviewSelectedIndex], "w_1500");

    useEffect(() => {
        if (pikaRefs[imageToPreviewSelectedIndex]?.current) {
            pikaRefs[imageToPreviewSelectedIndex]?.current.focus();
        }
    }, [imageToPreviewSelectedIndex]);

    return (
        <MediaOageStyled className={`lang-img-dpwz-${lang}`}>
            <img src={transformedSelectedImage} alt={langData?.arias?.book_image_preview} />
            <PreviewWrapperStyled role="radiogroup">
                {imgs?.map((poi, idx) => {
                    pikaRefs[idx] = React.createRef();

                    const isSelected = idx === imageToPreviewSelectedIndex;
                    const transformedImage = transformImage(poi, "w_1500");
                    let tabIndex = "-1";
                    if (isShown && isSelected) {
                        tabIndex = "0";
                    }

                    return (
                        <MediaPreviewStyled
                            role="radio"
                            aria-label={`${langData?.arias?.book_image_preview} ${idx + 1}`}
                            ref={pikaRefs[idx]}
                            onKeyDown={(e) => {
                                if (e.key === " " || e.key === "Enter") {
                                    s_imageToPreviewSelectedIndex(idx);
                                }
                                if (e.key === "ArrowLeft") {
                                    if (idx < imgs.length - 1) {
                                        s_imageToPreviewSelectedIndex(idx + 1);
                                    }
                                }
                                if (e.key === "ArrowRight") {
                                    if (idx > 0) {
                                        s_imageToPreviewSelectedIndex(idx - 1);
                                    }
                                }
                                if (e.key === "Home") {
                                    s_imageToPreviewSelectedIndex(0);
                                }
                                if (e.key === "End") {
                                    s_imageToPreviewSelectedIndex(imgs.length - 1);
                                }
                            }}
                            tabIndex={tabIndex}
                            className={isSelected ? "selected" : ""}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                e.cancelBubble = true;
                                s_imageToPreviewSelectedIndex(idx);
                                return false;
                            }}
                            key={idx}
                        >
                            <img
                                className="media-preview-item-eazd"
                                src={transformedImage}
                                alt={`${langData?.arias?.book_image_preview} ${idx + 1}`}
                            />
                        </MediaPreviewStyled>
                    );
                })}
            </PreviewWrapperStyled>
        </MediaOageStyled>
    );
};

export default MediaBookPage;
