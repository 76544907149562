import { getParams } from "../../../ApiCalls";
import React, { useState, useEffect, useContext } from "react";
import ParamContainer from "./ParamContainer";
import { Dna } from "react-loader-spinner";
import { CommonWrapperStyled } from "../../commonStyles";
import styled from "styled-components";
import { MainContext } from "../../../ContextState";
import { transformImage } from "../../../common/utils";
import Button from "../Button";
import Loader from "../Loader";

const KidsDetailsStyled = styled.div`
    @media (max-width: 480px) {
        margin-bottom: 0;
    }

    .details {
        margin-top: 18px;

        & > div {
            margin-top: 3px;
        }

        @media (max-width: 480px) {
            margin-top: 22px;
            padding-bottom: 0;
        }

        .span {
            margin: 5px 0 26px;
        }

        .label-ssde {
            color: #4d4a4a;
            font-size: 16px;
        }
    }
`;

const ParamsStyled = styled.div`
    display: grid;
    grid-gap: 9px;
    margin-top: 7px;

    @media (max-width: 1000px) {
        width: 100%;
        display: block;
        margin-top: 11px;
    }

    .right-params {
        @media (max-width: 1000px) {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 11px;
            margin: 0 auto;
        }

        @media (max-width: 767px) {
            grid-template-columns: 1fr 1fr;
        }

        @media (max-width: 480px) {
            grid-template-columns: 1fr;
            grid-gap: 0;
            margin: 0;
            padding: 0 10px;
            box-sizing: border-box;
        }

        .eyeColor {
        }
    }

    .hairStyle {
        @media (max-width: 1000px) {
            margin: 10px 10px 0;
        }

        .inner_params {
            @media (max-width: 1000px) {
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }

            @media (max-width: 480px) {
                grid-template-columns: 1fr 1fr;
            }
        }
    }
`;

function Stage3({
    progress,
    lang,
    loading,
    currentLang,
    setLoading,
    updateProgress,
    getItemAnimation,
    s_currentStage,
    setFocusToProgress
}) {
    const [error, setError] = useState("");

    const { paramsContext } = useContext(MainContext);
    const [params, s_params] = paramsContext;

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            if (Object.keys(params)?.length) {
                return;
            }
            setLoading("params");
            const paramsResult = await getParams();
            s_params(paramsResult);
            setTimeout(() => {
                setFocusToProgress();
            }, 0);
        } catch (error) {
            console.error("error fetching data params", error);
        } finally {
            setLoading(false);
        }
    };

    const nextStage = (event) => {
        if (!progress.hairColor || !progress.eyeColor || !progress.skinColor) {
            event.preventDefault();
            setError(lang.error.error_stage);
            return false;
        }
        s_currentStage(4);
    };

    const hairStyleGender = progress.gender === "male" ? params?.hairStyleMale : params?.hairStyleFemale;

    const isButtonDisabled = !progress.hairColor || !progress.eyeColor || !progress.skinColor;

    const isLoading = loading === "params" || Object.keys(params)?.length === 0;

    const transformedImage = transformImage(progress?.faceImageUrl, "w_300");

    return (
        <CommonWrapperStyled className="stage3-wrapper">
            <div className="stage3">
                <KidsDetailsStyled className="kid-details">
                    <div className="imageUploaded selectedImage">
                        <img src={transformedImage} alt={lang?.arias?.customer_image} />
                    </div>
                    <div className="details">
                        <div>
                            <span className="label-ssde">
                                {progress.gender === "male" ? lang.stage3.kids_name_male : lang.stage3.kids_name_female}
                            </span>
                            <span>{progress.name}</span>
                        </div>
                        <div>
                            <span className="label-ssde">
                                {progress.gender === "male" ? lang.stage3.kids_age : lang.stage3.kids_age_female}
                            </span>
                            <span>{progress.age}</span>
                        </div>
                    </div>
                </KidsDetailsStyled>
                {isLoading && <Loader height={80} width={80} />}

                {!isLoading && (
                    <>
                        <div className="horizontal-line-stage2" />
                        <ParamsStyled>
                            <div className="right-params">
                                <ParamContainer
                                    attribute={params?.hairColor}
                                    lang={lang}
                                    currentLang={currentLang}
                                    progress={progress}
                                    updateProgress={updateProgress}
                                />
                                <ParamContainer
                                    attribute={params?.skinColor}
                                    lang={lang}
                                    currentLang={currentLang}
                                    progress={progress}
                                    updateProgress={updateProgress}
                                />
                                <ParamContainer
                                    attribute={params?.eyeColor}
                                    lang={lang}
                                    currentLang={currentLang}
                                    progress={progress}
                                    updateProgress={updateProgress}
                                />
                            </div>
                            {/*<ParamContainer*/}
                            {/*    attribute={hairStyleGender}*/}
                            {/*    lang={lang}*/}
                            {/*    currentLang={currentLang}*/}
                            {/*    progress={progress}*/}
                            {/*    updateProgress={updateProgress}*/}
                            {/*/>*/}
                        </ParamsStyled>
                    </>
                )}
            </div>
            <div className="error">{error}</div>

            <Button onClick={nextStage} className="next-button" disabled={isButtonDisabled}>
                {lang.general.next}
            </Button>
        </CommonWrapperStyled>
    );
}

export default Stage3;
