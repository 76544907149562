import eng from "../languages/eng";
import heb from "../languages/heb";
import danish from "../languages/dk";

export const chooseLang = (lang) => {
    switch (lang) {
        case "en":
            return eng();
        case "he":
            return heb();
        case "da":
        case "dk":
            return danish();
        default:
            return eng();
    }
};
