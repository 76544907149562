import React from "react";
import styled from "styled-components";
import { VArrowSvg, XArrowSvg } from "../../../svg";
import { BadaraStyled } from "./CommonUploadImages";
import CONSTS from "../../../consts";

const WrapperStyled = styled.div`
    border-radius: 12px;
    display: grid;

    @media (min-height: 850px) {
        h3 {
            margin-bottom: 3vh;
        }
    }
`;

const TitleStyled = styled.div`
    display: grid;
    color: #000000;
    text-align: center;

    h3.several-things {
        font-size: 36px;
    }

    @media (max-width: 460px) {
        text-align: center;
        padding: 0;
        justify-self: center;
        align-items: center;

        h3.several-things {
            font-size: 24px;
        }
    }

    h3 {
        color: #000000;
        font-size: 34px;
        font-weight: 700;
        margin: 0;

        @media (max-width: 460px) {
            font-size: 28px;
        }
    }

    svg {
        @media (max-width: 460px) {
            width: 30px;
            height: 30px;
        }
    }
`;

const ContentListStyled = styled.div`
    text-align: center;
    color: #4D4D4D;

    p {
        margin: 6px auto;
        padding 0 10px;
        max-width: 80%;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.2;

        @media (max-width: 460px) {
            font-size: 14px;
            line-height: 1;
            text-align: center;
        }
    }
`;

const ImagesListStyled = styled.div`
    display: grid;
    grid-template-columns: 170px 146px 146px 146px;
    grid-gap: 28px;
    margin: 0 10px;
    margin-inline-start: 25px;
    justify-content: center;

    .many {
        margin-top: 10px;
    }

    .wrong-photo {
        img {
            border: 4px solid #cccccc;
            width: 140px;
            height: 140px;
            object-fit: cover;
            filter: grayscale(100%);
        }

        p {
            font-weight: 700;
            font-size: 18px;
            color: #999999;
        }

        .icon-style {
            top: 112px;
            right: 12px;
            z-index: 2;
            border: 2px solid #ffffff;
            width: 20px;
            height: 20px;

            svg {
                width: 10px;
                height: 10px;
            }
        }
    }

    .right-photo {
        img {
            border: 4px solid #39b54a;
            width: 150px;
            height: 150px;

            max-width: 160px;
            max-height: 160px;
            object-fit: cover;
        }

        p {
            font-weight: 700;
            font-size: 20px;
            color: #39b54a;
        }

        .icon-style {
            top: 124px;
            right: 20px;
            width: 22px;
            height: 22px;
        }
    }

    @media (max-height: 710px) {
        margin-inline-start: 0;
        position: reletive;
        display: flex;
        grid-gap: 0px;

        .wrong-photo {
            margin: 0;
            margin-left: -26px;

            img {
                width: 80px;
                height: 80px;
            }
            .icon-style {
                top: 68px;
                right: 10px;
            }

            p {
                max-width: 76px;
                text-align: center;
                padding: 0 10px;
                margin: 0 auto;
                box-sizing: border-box;
                font-size: 13px;
                color: #999999;
            }
        }

        .right-photo {
            margin-left: 0;
            left: 0;
            margin-right: 20px;
            padding-right: 20px;

            p {
                font-size: 15px;
                font-weight: 700;
                max-width: 96px;
                padding: 0 10px;
            }

            img {
                width: 80px;
                height: 80px;
            }

            .icon-style {
                top: 64px;
                right: 12px;
                svg {
                    width: 11px;
                    height: 11px;
                }
            }
        }

        .icon-style {
            top: 82px;
            z-index: 2;
            width: 14px;
            height: 14px;
            border: 2px solid #ffffff;

            svg {
                width: 8px;
                height: 8px;
            }
        }
    }

    @media (max-width: 1000px) {
        margin-inline-start: 0;
        position: reletive;
        display: flex;
        grid-gap: 0px;

        .wrong-photo {
            margin: 0;
            margin-left: -26px;

            img {
                width: 80px;
                height: 80px;
            }
            .icon-style {
                top: 68px;
                right: 10px;
            }

            p {
                max-width: 76px;
                text-align: center;
                padding: 0 10px;
                margin: 0 auto;
                box-sizing: border-box;
                font-size: 13px;
                color: #999999;
            }
        }

        .right-photo {
            margin-left: 0;
            left: 0;
            margin-right: 20px;
            padding-right: 20px;

            p {
                font-size: 15px;
                font-weight: 700;
                max-width: 96px;
                padding: 0 10px;
            }

            img {
                width: 80px;
                height: 80px;
            }

            .icon-style {
                top: 64px;
                right: 12px;
                svg {
                    width: 11px;
                    height: 11px;
                }
            }
        }

        .icon-style {
            top: 82px;
            z-index: 2;
            width: 14px;
            height: 14px;
            border: 2px solid #ffffff;

            svg {
                width: 8px;
                height: 8px;
            }
        }
    }

    @media (max-width: 400px) {
        width: ${({ isHeLang }) => (isHeLang ? 90 : 100)}%;
        margin-inline-start: 0;
        align-items: baseline;

        .wrong-photo {
            position: reletive;
            p {
                font-size: 9px;
                width: 50%;
                padding-right: 0;
                left: -10px;
                transform: ${({ isHeLang }) => (isHeLang ? "translateX(-30px)" : "translateX(22px)")};
            }
        }

        .wrong-photo {
            margin-left: ${({ isHeLang }) => (isHeLang ? -40 : -48)}px;
            width: 10%;
            height: auto;

            img {
                width: 50%;
                min-width: 60px;
                height: auto;
            }

            .icon-style {
                right: 20%;
                top: 50%;
                width: 12px;
                height: 12px;
                border: 1px solid #ffffff;

                svg {
                    width: 6px;
                    height: 6px;
                }
            }
        }

        .right-photo {
            padding: 0;
            margin-right: ${({ isHeLang }) => (isHeLang ? 0 : 48)}px;

            img {
                width: 60%;
                height: auto;
                min-width: 60px;
            }

            .icon-style {
                right: 20%;
                top: 48%;
                width: 12px;
                height: 12px;
                border: 1px solid #ffffff;

                svg {
                    width: 6px;
                    height: 6px;
                }
            }
        }
    }

    @media (max-width: 350px) {
        p {
            display: none;
        }

        .right-photo {
            .icon-style {
                top: 55px;
            }
        }
        .wrong-photo {
            .icon-style {
                top: 55px;
            }
        }
    }

    @media (min-height: 850px) {
        margin: 10px;
    }
`;

const ImagesWrapperStyled = styled.div`
    display: grid;
    margin: 23px auto 25px;
    z-index: ${({ index }) => 10 - index};

    &.many {
        display: block;
        width: auto;
        margin-top: 10px;
        margin-bottom: 0;

        p {
            margin: 4px 0 0 0;
        }

        @media (max-width: 400px) {
            width: 30%;
            justify-content: normal;
        }
    }

    img {
        border-radius: 50%;
    }

    .lllizcsr {
        svg {
            margin-top: 48px;
        }
    }

    div {
        position: relative;
    }

    p {
        font-size: 14px;
        font-weight: 400;
        margin: 7px 0 0 0;
        line-height: 1;
    }
`;

const UploadImagesDescriptionV2 = ({ langData, lang }) => {
    const imagesToShow = ["normal", 6002, 6006, 6003];

    return (
        <WrapperStyled>
            <TitleStyled>
                <h3 className="several-things">{langData.stage1.several_things}</h3>
            </TitleStyled>
            <ContentListStyled>
                <p>{langData.stage1.several_things_cont.first}</p>
            </ContentListStyled>

            <ImagesListStyled isHeLang={lang === "he"}>
                {imagesToShow.map((imageCode, index) => {
                    const isNormal = imageCode === "normal";
                    const errorLabel = langData.image_error_labels[imageCode];
                    const errorImage = CONSTS.error_images_links[imageCode];

                    let classes = "icon-style";
                    if (isNormal) {
                        classes += " norm";
                    }

                    return (
                        <ImagesWrapperStyled
                            index={index}
                            key={imageCode}
                            className={`${isNormal ? "right-photo" : "wrong-photo"} many`}
                        >
                            <div>
                                <BadaraStyled className={classes}>
                                    {!isNormal && <XArrowSvg />}
                                    {isNormal && <VArrowSvg />}
                                </BadaraStyled>
                                <img src={errorImage} alt="" />
                                <p>{errorLabel}</p>
                            </div>
                        </ImagesWrapperStyled>
                    );
                })}
            </ImagesListStyled>
        </WrapperStyled>
    );
};

export default UploadImagesDescriptionV2;
