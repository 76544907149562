import "./styles/normalize.css";
import "./styles/animation.css";
import "react-tooltip/dist/react-tooltip.css";
import "react-mobile-cropper/dist/style.css";
import Stages from "./pages/Stages";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { MainContextProvider } from "./ContextState";
import Wrapper from "./Wrapper";
import Resizer from "./common/Resizer";
import OtpPage from "./pages/Otp";

function App() {
    return (
        <MainContextProvider>
            <div>
                <Router>
                    <Routes>
                        <Route
                            element={
                                <Resizer>
                                    <Wrapper />
                                </Resizer>
                            }
                        >
                            <Route path="/" element={<Stages />} />
                            <Route path="/otp" element={<OtpPage />} />
                        </Route>
                    </Routes>
                </Router>
            </div>
        </MainContextProvider>
    );
}

export default App;
