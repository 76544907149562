import React from "react";
import Modal from "../../common/Modal";
import { getRelevantTemplateImage, getRelevantTemplateTitle } from "../../common/utils";
import styled from "styled-components";

const ModalContentStyled = styled.div`
    max-width: 670px;
    min-width: 600px;

    @media (max-width: 767px) {
        min-width: 100%;
        max-width: 100%;
    }

    img {
        border-radius: 8px;
        width: 150px;
    }

    h3 {
        font-size: 30px;
        margin: 20px;
    }
`;

const PlotModal = ({ openSelectionFunc, lang, closeModalForce, ariaTitle, selectedPlotItem, progress }) => {
    console.log("pew", selectedPlotItem);
    return (
        <Modal
            role="alertdialog"
            isOpen={true}
            onClose={openSelectionFunc}
            lang={lang}
            closeModal={closeModalForce}
            backGroundClose={true}
            ariaTitle={ariaTitle}
        >
            <ModalContentStyled>
                <img src={getRelevantTemplateImage(selectedPlotItem, progress)} alt="" />
                <h3>{getRelevantTemplateTitle(selectedPlotItem, progress)}</h3>
                <p>{selectedPlotItem.plot}</p>
            </ModalContentStyled>
        </Modal>
    );
};

export default PlotModal;
