import React from "react";
import ModalIframe from "../stages/components/modal/ModalIframe";
import ModalRegular from "../stages/components/modal/ModalRegular";

const Modal = (props) => {
    const isIframe = window.self !== window.top;

    return (
        <>
            {isIframe && <ModalIframe {...props} />}
            {!isIframe && <ModalRegular {...props} />}
        </>
    );
};

export default Modal;
