export const transformImage = (url, value) => {
    let result = url;
    if (url && value) {
        const wordAfter = "image/upload/";

        const parts = url.split(wordAfter);
        result = parts.join(wordAfter + value + "/");
    }
    return result;
};

export const getMobileQuery = () => {
    return window.innerWidth;
};

export function getFontSize(sizeName, partOfBook) {
    let size = 15;
    switch (sizeName) {
        case "S":
            size = 25;
            break;
        case "M":
            size = 30;
            break;
        case "L":
            size = 40;
            break;
        case "XL":
            size = 50;
            break;
    }
    return size;
}

export function makeImgAsPrint(image) {
    let result = image;
    if (image.includes("https://mebook.b-cdn.net/")) {
        result = image.replace("https://mebook.b-cdn.net/", "https://print.mebook.ai/");
    }

    if (image.includes("https://cdn.mebook.ai/")) {
        result = image.replace("https://cdn.mebook.ai/", "https://print.mebook.ai/");
    }

    return result;
}

export const getRelevantTemplateTitle = (template, progress) => {
    return progress.gender === "female" ? template.titleFemale : template.titleMale;
};

export const getRelevantTemplateImage = (template, progress) => {
    let imageToShow = transformImage(template?.imageUrl, "w_500");
    if (template.imageUrlFemale && progress.gender === "female") {
        imageToShow = transformImage(template?.imageUrlFemale, "w_500");
    }
    if (template.imageUrlMale && progress.gender === "male") {
        imageToShow = transformImage(template?.imageUrlMale, "w_500");
    }
    return `${imageToShow}?width=400`;
};

export function normalizePhoneNumber(phoneNumber) {
    if (!phoneNumber) return null;
    return phoneNumber;
    // if (phoneNumber[0] === '0') {
    //     return `+972`+ phoneNumber.substring(1);
    // } else {
    //     return `+972`+ phoneNumber;
    // }
}
