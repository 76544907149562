import React from "react";
import styled from "styled-components";

const WrapperItemsStyled = styled.div`
    min-width: 600px;
    max-width: 650px;
    max-height: 500px;
    min-height: 200px;
    grid-gap: 12px;
    margin-top: 12px;
    padding: 0 40px;
    overflow-y: auto;
    white-space: pre-line;

    @media (max-height: 767px) {
        height: 80vh;
    }

    @media (max-width: 767px) {
        width: 100%;
        min-width: auto;
        height: 80vh;
        padding: 1%;
    }

    h3,
    h4,
    p {
        margin: 0;
        padding: 0;
    }

    h3 {
        margin: 0 0 5px;
        padding-top: 9px;
        font-size: 19px;
    }

    h4 {
        font-size: 17px;
    }

    div {
        margin: 14px 0;
    }

    p {
    }
`;
const Terms = ({ langData, lang }) => {
    const styles = {
        textAlign: "left"
    };

    if (lang === "he") {
        styles.textAlign = "right";
    }

    return (
        <WrapperItemsStyled style={styles}>
            <h3>{langData.terms_modal_h}</h3>
            {langData.terms_modal.map((kaki) => {
                return (
                    <div key={kaki.title}>
                        <h4>{kaki.title}</h4>
                        <p>{kaki.p}</p>
                    </div>
                );
            })}
            {langData.terms_modal_h2 && <h3>{langData.terms_modal_h2}</h3>}
            {langData.terms_modal2 &&
                langData.terms_modal2.map((kaki) => {
                    return (
                        <div key={kaki.title}>
                            <h4>{kaki.title}</h4>
                            <p>{kaki.p}</p>
                        </div>
                    );
                })}
        </WrapperItemsStyled>
    );
};

export default Terms;
